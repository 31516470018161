import { defineComponent, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import './index.less'

export default defineComponent({
    setup () {

        const router = useRouter()

        const state: any = reactive({
            times: 3,
            show: true
        })

        onMounted(() => {
            const res = localStorage.getItem('YL')
            if (!res) {
                getCode()
            }
        })

        const getCode = () => {
            state.show = false
            let timer: any = setInterval(() => {
                state.times--
                if (state.times === 0) {
                    state.show = true
                    state.times = 3
                    clearInterval(timer)
                    timer = null
                } 
            }, 1000) 
        }

        const onsRecharge= () => {
            localStorage.setItem('YL', 'YL')
            router.push({ path: '/recharge' })
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>账户充值</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                
                <div class="agree-header-box">
                    <img src="https://img.fanwoon.com/redtishi%402x.png" />
                    <span>翻腕充值交易说明条款</span>
                </div>
                <div class="agree-info-box">
                    <div>1. 充值的目的为了享受翻腕平台提供的服务，是用户的自愿行为；</div>
                    <div>2. 您确保充值的金额来源正当、合理、合法，如违反中华人民共和国法律法规，由用户自行承担风险及损失，且本平台有权根据国家的相关规定及本平台的服务协议立即采取下列一个或多个措施:立即停止提供服务;对相关信息予以下线、删除或要求整改;封禁用户账号等。对于用户已支付的服务费用，本平台有权不予退还；</div>
                    <div>3. 充值后，您务必妥善保管账号信息，不得提供给任何第三方使用或从事任何可能使用户名、密码存在泄露危险的行为（包括但不限于在第三方平台授权代理或登录），因此所导致的账户余额被恶意使用或衍生的任何损害，本平台不承担任何责任；</div> 
                    <div>4. 充值金额后，若3个月内未使用，可申请退款，退款将会以银行转账的方式，退回到您申请退款时填写的银行账户中，退款的周期为申请退款当天至30个工作日内完成，最终以银行的实际到账时间为准；</div>   
                    <div>5. 充值的金额用于发放给推荐者的内推奖金、平台面试邀约服务费等费用；</div> 
                    <div>6. 通过平台进行招聘等服务，且涉及到扣款时，平台会提示您进行确认，一旦您确认扣款，将无法退款，如果因为您的误操作等行为导致的扣款，本平台不承担任何责任；</div> 
                    <div>7. 本平台仅支持通过由上海往矣科技有限公司研发的「翻腕」小程序、「翻腕」公众号、「翻腕」移动端三个渠道进行商家账号充值，用户通过非上述渠道进行的充值行为，若产生的任何损害，本平台不承担任何责任。</div>
                </div>
                <div class="agree-footer">
                    {
                        state.show ? <button class="agree-btn" onClick={onsRecharge}>我已阅读并同意上述内容</button> : 
                        <button class="nomarl-btn" disabled>我已阅读并同意上述内容({state.times}s)</button> 
                    }
                </div>
            </div>
        )
    }
})